import { ClockIcon, HomeIcon, ViewListIcon } from "@heroicons/react/outline";

export const navigation = [
  { name: "Главная", href: "#", icon: HomeIcon, current: true },
  { name: "Портфель", href: "#", icon: ViewListIcon, current: false },
  { name: "История", href: "#", icon: ClockIcon, current: false },
];
export const teams = [
  { name: "Healthcare", href: "#", bgColorClass: "bg-indigo-500" },
  { name: "Materials", href: "#", bgColorClass: "bg-emerald-500" },
  { name: "Industrial", href: "#", bgColorClass: "bg-amber-500" },
  { name: "Consumer", href: "#", bgColorClass: "bg-amber-500" },
  { name: "IT", href: "#", bgColorClass: "bg-amber-500" },
  { name: "Financial", href: "#", bgColorClass: "bg-amber-500" },
  { name: "Utilities", href: "#", bgColorClass: "bg-amber-500" },
  { name: "Real Estate", href: "#", bgColorClass: "bg-amber-500" },
  { name: "Electrocars", href: "#", bgColorClass: "bg-emerald-500" },
  { name: "Telecom", href: "#", bgColorClass: "bg-amber-500" },
  { name: "Energy", href: "#", bgColorClass: "bg-red-500" },
  { name: "Green Buildings", href: "#", bgColorClass: "bg-emerald-500" },
  { name: "Ecomaterials", href: "#", bgColorClass: "bg-emerald-500" },
  { name: "Green Energy", href: "#", bgColorClass: "bg-emerald-500" },
];
export const projects = [
  {
    id: 1,
    title: "Утренние торги",
    initials: "07:00",
    team: "Engineering",
    members: [
      {
        name: "Dries Vincent",
        handle: "driesvincent",
        imageUrl:
          "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
      {
        name: "Lindsay Walton",
        handle: "lindsaywalton",
        imageUrl:
          "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
      {
        name: "Courtney Henry",
        handle: "courtneyhenry",
        imageUrl:
          "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
      {
        name: "Tom Cook",
        handle: "tomcook",
        imageUrl:
          "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    ],
    totalMembers: 100,
    lastUpdated: "March 17, 2020",
    pinned: true,
    bgColorClass: "bg-pink-600",
  },
  // More projects...
];
