import React, { useEffect } from "react";

import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { stocksDataState } from "../../atoms";

export default function QuotesUS({ stock }) {
  // {
  //   "symbol": "AAPL",
  //   "trade": {
  //       "t": "2021-11-12T21:46:40.653626613Z", // Timestamp in RFC-3339 format with nanosecond precision.
  //       "x": "V", // Exchange where the trade happened.
  //       "p": 149.99, // Trade price.
  //       "s": 250, // Trade size.
  //       "c": [ // Trade conditions.
  //           "@",
  //           "T"
  //       ],
  //       "i": 5585, // Trade ID.
  //       "z": "C" // Tape.
  //   }
  // }

  const [stocksData, setStocksData] = useRecoilState(stocksDataState);

  const alpacaQuotes = useQuery(
    `alpaca-quotes-${stock.initials}`,
    async () => {
      return await (
        await fetch(
          `https://data.alpaca.markets/v2/stocks/${stock.initials}/trades/latest`,
          {
            cache: "no-cache",
            method: "GET",
            mode: "cors",
            headers: {
              "APCA-API-KEY-ID": process.env.REACT_APP_APCA_API_KEY_ID,
              "APCA-API-SECRET-KEY": process.env.REACT_APP_APCA_API_SECRET_KEY,
            },
          }
        )
      ).json();
    },
    {
      // Refetch the data every second
      refetchInterval: 1000,
    }
  );

  useEffect(() => {
    setStocksData({
      ...stocksData,
      [stock.initials]: {
        ...stocksData[stock.initials],
        ["alpacaQuotes"]: alpacaQuotes?.data,
      },
    });
  }, [alpacaQuotes.data]);

  if (
    alpacaQuotes.isLoading ||
    alpacaQuotes.data === undefined ||
    alpacaQuotes.data === null ||
    alpacaQuotes.data.trade === undefined ||
    alpacaQuotes.data.trade === null
  )
    return (
      <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
        Загрузка...
      </td>
    );

  return (
    <>
      <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
        {alpacaQuotes.data.trade.p} ({alpacaQuotes.data.trade.s})
      </td>

      <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
        {alpacaQuotes.data.trade.t.split(".")[0]}
      </td>
    </>
  );
}
