import React, { useState, useRef, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

export default function OrderBookUS({ alorJWT, stock }) {
  const depth = 10;

  // WebSocket подписка
  const ws = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [bookData, setBookData] = useState(null);

  useEffect(() => {
    let authMessage = {
      action: "auth",
      key: process.env.REACT_APP_APCA_API_KEY_ID,
      secret: process.env.REACT_APP_APCA_API_SECRET_KEY,
    };

    let subscriptionMessage = {
      action: "subscribe",
      trades: ["AAPL"],
      quotes: ["AAPL"],
    };

    if (!isPaused) {
      ws.current = new WebSocket("wss://stream.data.alpaca.markets/v2/sip"); // создаем ws соединение
      ws.current.onopen = () => {
        ws.current.send(JSON.stringify(authMessage));
        ws.current.send(JSON.stringify(subscriptionMessage));
      }; // callback на ивент открытия соединения
      ws.current.onclose = () => {
        console.log("Alpaca - WebSocket соединение закрыто"); // callback на ивент закрытия соединения
      };
      gettingData();
    }

    return () => ws.current.close(); // кода меняется isPaused - соединение закрывается
  }, [ws, isPaused]);

  const gettingData = useCallback(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e) => {
      //подписка на получение данных по вебсокету
      if (isPaused) return;
      const message = JSON.parse(e.data);

      if (!message.msg) setBookData(message); // send only data
      console.log(message);
    };
  }, [isPaused]);

  if (
    bookData === null ||
    bookData === undefined ||
    bookData.data === undefined ||
    bookData.data === null ||
    bookData.data.asks === undefined ||
    bookData.data.asks === null ||
    bookData.data.bids === undefined ||
    bookData.data.bids === null
  )
    return <div className="text-sm text-gray-600">Загрузка...</div>;

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block w-96 sm:px-3 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200">
            <p className="text-xs text-gray-600">SPBX Order Book (L2)</p>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="p-1 text-left text-xs text-gray-500 font-light w-1/4"
                  >
                    Size
                  </th>
                  <th
                    scope="col"
                    className="p-1 text-right text-xs text-emerald-500 font-light w-1/4"
                  >
                    Bid
                  </th>
                  <th
                    scope="col"
                    className="p-1 text-left text-xs text-red-500 font-light w-1/4"
                  >
                    Ask
                  </th>
                  <th
                    scope="col"
                    className="p-1 text-right text-xs text-gray-500 font-light w-1/4"
                  >
                    Size
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {[...Array(depth)].map((x, i) => (
                  <tr key={`order-book-record-${i}`}>
                    <td className="p-1 text-left whitespace-nowrap text-xs text-gray-900 w-1/4">
                      {bookData.data.bids[i] !== undefined &&
                      bookData.data.bids[i] !== null
                        ? bookData.data.bids[i].volume
                        : null}
                    </td>
                    <td className="p-1 text-right whitespace-nowrap text-xs text-emerald-500 w-1/4">
                      {bookData.data.bids[i] !== undefined &&
                      bookData.data.bids[i] !== null
                        ? bookData.data.bids[i].price
                        : null}
                    </td>
                    <td className="p-1 text-left whitespace-nowrap text-xs text-red-500 w-1/4">
                      {bookData.data.asks[i] !== undefined &&
                      bookData.data.asks[i] !== null
                        ? bookData.data.asks[i].price
                        : null}
                    </td>
                    <td className="p-1 text-right whitespace-nowrap text-xs text-gray-500 w-1/4">
                      {bookData.data.asks[i] !== undefined &&
                      bookData.data.asks[i] !== null
                        ? bookData.data.asks[i].volume
                        : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
