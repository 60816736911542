import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { useRecoilState, useRecoilValue } from "recoil";
import { stocksDataIndicatorsState, stocksDataState } from "../../atoms";

import classNames from "../../helpers";

import stocks from "../data/stocks.json";
import OrderBookSPBX from "./OrderBookSPBX";
// import Prints from "./Prints";
import Header from "./Header";
import QuotesSPBX from "./QuotesSPBX";
import QuotesUS from "./QuotesUS";
import OrderBookUS from "./OrderBookUS";

function Table() {
  const [openedStock, setOpenedStock] = useState(null);
  const [stocksData, setStocksData] = useRecoilState(stocksDataState);

  const stocksDataIndicators = useRecoilValue(stocksDataIndicatorsState);

  // Получение JWT токена
  const alorJWT = useQuery("AlorJWT", () =>
    fetch(
      `https://oauth.alor.ru/refresh?token=${process.env.REACT_APP_ALOR_REFRESH_TOKEN}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => res.json())
  );

  useEffect(() => {
    if (!alorJWT.isLoading && !alorJWT.isError) {
      setStocksData({ ...stocksData, ["alorJWT"]: alorJWT.data });
    }
  }, [alorJWT.data]);

  // useEffect(() => console.log("DATA:", stocksData), [stocksData]);

  return (
    <div className="hidden mt-8 sm:block">
      <div className="align-middle inline-block min-w-full border-b border-gray-200">
        {alorJWT.isLoading ? "Loading Alor JWT" : ""}
        <table className="min-w-full table-fixed">
          <Header />
          <tbody className="bg-white divide-y divide-gray-100">
            {stocks.map((stock, index) => {
              return (
                <tr key={`stock-${index}`}>
                  <td className="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                    <div className="flex items-center space-x-3 lg:pl-2">
                      <div
                        className={classNames(
                          "bg-blue-600",
                          "shrink-0 w-2.5 h-2.5 rounded-full"
                        )}
                        aria-hidden="true"
                      />
                      <a
                        href={`https://www.webull.com/quote/${stock.market.toLowerCase()}-${stock.initials.toLowerCase()}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="truncate hover:text-gray-600"
                      >
                        <span>
                          {stock.initials}{" "}
                          <span className="text-gray-500 font-normal">
                            ({stock.name})
                          </span>
                        </span>
                      </a>
                    </div>
                    {openedStock === stock ? (
                      <div className="p-1 flex space-x-2">
                        <OrderBookSPBX alorJWT={alorJWT} stock={stock} />
                        <OrderBookUS stock={stock} />
                      </div>
                    ) : null}
                  </td>
                  <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                    {stocksDataIndicators[stock.initials] || 0}
                  </td>
                  <QuotesSPBX stock={stock} />
                  <QuotesUS stock={stock} />
                  <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                    <div
                      onClick={() => setOpenedStock(stock)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Купить
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;
