import SearchHeader from "./SearchHeader";
import ProfileDropdown from "./ProfileDropdown";
import PageTitleAndActions from "./PageTitleAndActions";
import PinnedProjects from "./PinnedProjects";
import ProjectsListMobile from "./ProjectsListMobile";
import Table from "./Table";

function MainColumn() {
  return (
    <div className="lg:pl-64 flex flex-col">
      <SearchHeader>
        <ProfileDropdown />
      </SearchHeader>
      <main className="flex-1">
        <PageTitleAndActions />
        <PinnedProjects />
        {/* Only on smallest breakpoint */}
        <ProjectsListMobile />
        {/* Small breakpoint and up */}
        <Table />
      </main>
    </div>
  );
}

export default MainColumn;
