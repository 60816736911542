import UserAccountDropdown from "./UserAccountDropdown";
import SidebarSearch from "./SidebarSearch";
import Navigation from "./Navigation";

function SidebarDesktop() {
  return (
    <div className="hidden lg:flex lg:flex-col lg:w-64 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pt-5 lg:pb-4 lg:bg-gray-100">
      <div className="flex items-center shrink-0 px-6">
        <img
          className="h-8 w-auto"
          src="https://tailwindui.com/img/logos/workflow-logo-purple-500-mark-gray-700-text.svg"
          alt="Workflow"
        />
      </div>
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="mt-6 h-0 flex-1 flex flex-col overflow-y-auto">
        <UserAccountDropdown />
        <SidebarSearch />
        <Navigation />
      </div>
    </div>
  );
}

export default SidebarDesktop;
