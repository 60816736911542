export default function Header() {
  return (
    <thead>
      <tr className="border-t border-gray-200">
        <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/5">
          <span className="lg:pl-2">Акция</span>
        </th>
        <th className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-1/5">
          Delta, $
        </th>
        <th className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-1/5">
          Цена SPBX, $
        </th>
        <th className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-1/5">
          Цена US, $
        </th>
        <th className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-1/5">
          Время
        </th>
        <th className="pr-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-1/5" />
      </tr>
    </thead>
  );
}
