import { atom, selector } from "recoil";

export const sidebarOpenState = atom({
  key: "sidebarOpenState", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const stocksDataState = atom({
  key: "stocksDataState", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const stocksDataIndicatorsState = selector({
  key: "stocksDataIndicatorsState",
  get: ({ get }) => {
    const stocksData = get(stocksDataState);

    let result = {};
    let delta;

    if (Object.entries(stocksData).length !== 0) {
      Object.keys(stocksData).forEach((key, index) => {
        delta = 0;
        if (
          key !== "alorJWT" &&
          stocksData[key].alpacaQuotes !== undefined &&
          stocksData[key].alpacaQuotes !== null &&
          stocksData[key].alorQuotes !== undefined &&
          stocksData[key].alorQuotes !== null
        ) {
          delta =
            stocksData[key].alpacaQuotes.trade.p -
            stocksData[key].alorQuotes.last_price;

          result = { ...result, [key]: delta.toFixed(2) };
        }
      });
    }

    return result;
  },
});
