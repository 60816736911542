import SidebarDesktop from "./components/SidebarDesktop";
import SidebarMobile from "./components/SidebarMobile";
import MainColumn from "./components/MainColumn";

function App() {
  return (
    <div className="min-h-full">
      <SidebarMobile />
      <SidebarDesktop />
      <MainColumn />
    </div>
  );
}

export default App;
