import React, { useEffect } from "react";

import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { stocksDataState } from "../../atoms";

export default function QuotesSPBX({ stock }) {
  // [
  //   {
  //     "symbol": "KMEZ",
  //     "exchange": "MOEX",
  //     "description": "КМЗ",
  //     "ask": 171.87,
  //     "bid": 171.82,
  //     "prev_close_price": 174.9,
  //     "last_price": 171.82,
  //     "last_price_timestamp": 1610982677,
  //     "change": -3.08,
  //     "change_percent": -1.76,
  //     "high_price": 176.02,
  //     "low_price": 170.33,
  //     "accruedInt": 0,
  //     "accrued_interest": 0,
  //     "volume": 38767080,
  //     "open_interest": null,
  //     "open_price": 175.07,
  //     "yield": null,
  //     "lotsize": 10,
  //     "lotvalue": 1990.2,
  //     "facevalue": 5,
  //     "type": "CS"
  //   }
  // ]

  const [stocksData, setStocksData] = useRecoilState(stocksDataState);

  const alorQuotes = useQuery(
    `alor-quotes-${stock.initials}`,
    async () => {
      return await (
        await fetch(
          `https://api.alor.ru/md/v2/Securities/SPBX:${stock.initials}/quotes`,
          {
            cache: "no-cache",
            method: "GET",
            mode: "cors",
            headers: {
              Authorization: `Bearer ${stocksData.alorJWT?.AccessToken}`,
            },
          }
        )
      ).json();
    },
    {
      // Refetch the data every second
      refetchInterval: 1000,
    }
  );

  useEffect(
    () =>
      setStocksData({
        ...stocksData,
        [stock.initials]: {
          ...stocksData[stock.initials],
          ["alorQuotes"]:
            alorQuotes?.data !== undefined && alorQuotes?.data !== null
              ? alorQuotes?.data[0]
              : null,
        },
      }),
    [alorQuotes.data]
  );

  if (
    alorQuotes.isLoading ||
    alorQuotes.data === undefined ||
    alorQuotes.data === null ||
    alorQuotes.data[0] === undefined ||
    alorQuotes.data[0] === null ||
    alorQuotes.data[0].last_price === undefined ||
    alorQuotes.data[0].last_price === null
  )
    return (
      <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
        Загрузка...
      </td>
    );

  return (
    <>
      <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
        {alorQuotes.data[0].last_price}
      </td>
    </>
  );
}
